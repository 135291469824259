import { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import ChatPresentation from 'chat-ui/ChatPresentation';
import useChatStore from 'chat-ui/src/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosInstanceChat, { getChatHistory } from '@/axios/axiosInstanceChat';
import FullPageLoader from '../FullPageLoader';
import { EChatErrors } from '@/enums/enums';

// const chatCannotRegisterError = `We cannot find this chat.
// Please check the link or contact the owner of the chat.`;
const errorSendingMessage = "We can't send your message. Please try again.";

interface ChatProps {
  projectId: string | undefined;
  chatHeight: string;
  setProjectConsent: React.Dispatch<React.SetStateAction<boolean>>;
}

function Chat({ projectId, chatHeight, setProjectConsent }: ChatProps) {
  const {
    addErrorMessage,
    setInitialMessages,
    setCurrentProjectToken,
    currentProjectToken,
    setSeen,
    setRefreshChatHistory,
  } = useChatStore((state) => state);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const isSandboxChat = searchParams.get('environment');
  const sandboxLink = isSandboxChat === 'sandbox';
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  // eslint-disable-next-line consistent-return
  const refreshChatHistory = useCallback(async () => {
    try {
      const data = await getChatHistory(currentProjectToken);
      setInitialMessages(data);
      setSeen();
    } catch (err) {
      const axiosError = err as AxiosError;
      const chatError = axiosError?.response?.status;
      if (chatError === EChatErrors.CHAT_EXPIRED) {
        return navigate(`/chat/error?error_status=${EChatErrors.CHAT_EXPIRED}`);
      }
      return addErrorMessage(errorSendingMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectToken]);

  useEffect(() => {
    setRefreshChatHistory(() => refreshChatHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectToken]);

  const tokenFromStorage = localStorage.getItem(`current_project_token_${projectId}`) as string;
  const tokenFromStorageSandbox = localStorage.getItem(`current_project_token_${projectId}/sandbox`) as string;

  const handleChatRegister = useCallback(
    async () => {
      setLoading(true);
      try {
        const token = sandboxLink ? tokenFromStorageSandbox : tokenFromStorage;
        setCurrentProjectToken(token);
      } catch (error) {
        console.error('Error setting project token:', error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokenFromStorage, tokenFromStorageSandbox, projectId],
  );

  useEffect(() => {
    if (currentProjectToken) {
      getChatHistory(currentProjectToken)
        .then((data) => {
          setInitialMessages(data);
          setSeen();
        })
        .catch((error) => {
          const axiosError = error as AxiosError;
          localStorage.removeItem(`current_project_token_${projectId}${sandboxLink && '/sandbox'}`);
          localStorage.removeItem(`project_consent_${projectId}${sandboxLink ? '/sandbox' : ''}`);
          if (axiosError && axiosError.response?.status !== 401) {
            addErrorMessage(errorSendingMessage);
            localStorage.clear();
            setProjectConsent(false);
            window.location.reload();
          }
        });
    }
  }, [currentProjectToken]);

  const handleRestartChat = useCallback(async () => {
    setIsRefreshLoading(true);
    try {
      const currentConversationId = localStorage.getItem(`current_conversation_id/sandbox/${projectId}`);
      const response = await axiosInstanceChat.post(
        `${import.meta.env.VITE_CHAT_URL}/chat/sandbox/reset${projectId}/${currentConversationId}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${currentProjectToken}`,
          },
        },
      );

      setIsRefreshLoading(false);
      setInitialMessages(response.data.messages);
    } catch {
      setIsRefreshLoading(false);
      addErrorMessage(errorSendingMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, currentProjectToken]);

  useEffect(() => {
    const currentToken = localStorage.getItem(`current_project_token_${projectId}${sandboxLink && '/sandbox'}`);
    if (currentToken) {
      setCurrentProjectToken(currentToken);
      setLoading(false);
    } else if (!currentToken) {
      handleChatRegister();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (isSandboxChat !== null && isSandboxChat !== 'sandbox') {
      navigate('/chat/error-page');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSandboxChat]);

  if (loading) return <FullPageLoader />;

  return (
    <ChatPresentation
      chatHeight={chatHeight}
      isTestChat={false}
      handleRestartChat={handleRestartChat}
      isRefreshLoading={isRefreshLoading}
    />
  );
}

export default Chat;
