import AudioPlayer from './AudioPlayer';
import { cn } from '../../../lib/utils';
import useTheme from '../../../hooks/useTheme';

type TMessageAudio = {
  isUser: boolean;
} & TMessageAtachment;

function AudioMessage({ url, isUser }: TMessageAudio) {
  const { currentTheme } = useTheme();
  return (
    <div>
      <div
        style={{ backgroundColor: isUser ? currentTheme?.['chat-primary'] : "#FFFFFF" }}
        className={cn(isUser ? 'text-white' : 'text-dark-text')}>
        <AudioPlayer url={url} isUser={isUser} />
      </div>
    </div>
  );
}

export default AudioMessage;
